/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import TextField from '@common_textfield';
import Button from '@common_button';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from '@modules/login/pages/default/components/style';
import { useRouter } from 'next/router';
import Link from 'next/link';

const Login = (props) => {
    const classes = useStyles();
    const {
        formik,
        dataConfig,
        storeLogo,
        t,
    } = props;
    const router = useRouter();

    return (
        <div className={clsx(classes.loginContainer)}>
            <div className={classes.containLeft}>
                <div className={classes.headerLogin}>
                    <img
                        alt="logo"
                        src={storeLogo?.logo || '/assets/img/swiftoms_logo_expanded.png'}
                        style={{ maxHeight: 52, cursor: 'pointer' }}
                        onClick={() => router.push('/')}
                    />
                </div>
                <div className={classes.loginContent}>
                    <Typography variant="h6" gutterBottom className={classes.textTitle}>
                        {t('login:signIn')}
                    </Typography>
                    <form onSubmit={(e) => formik.handleSubmit(e)}>
                        <div className="row center-xs start-sm">
                            <div className={clsx('col-xs-12 col-sm-12', classes.formField)}>
                                <TextField
                                    name="email"
                                    placeholder={t('login:email')}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    className={classes.textInput}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img alt="" src="/assets/img/icon-email.svg" className={classes.iconImg} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={!!(formik.touched.email && formik.errors.email)}
                                    helperText={(formik.touched.email && formik.errors.email) || ''}
                                />
                            </div>
                            <div className={clsx('col-xs-12 col-sm-12', classes.formField)}>
                                <TextField
                                    name="password"
                                    placeholder={t('login:password')}
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    className={classes.textInput}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img alt="" src="/assets/img/icon-lock.svg" className={classes.iconImg} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={!!(formik.touched.password && formik.errors.password)}
                                    helperText={(formik.touched.password && formik.errors.password) || ''}
                                />
                            </div>
                            <div className={clsx('col-xs-12 col-sm-12', classes.formField)}>
                                <Button type="submit" variant="contained" className={classes.btnLogin}>
                                    <span className={classes.btnLoginText}>{t('login:login')}</span>
                                </Button>
                                <Typography variant="h6" className={classes.btnTextForgot}>
                                    <Link href="/forgotpassword">
                                        <a>{t('login:forgotPassword')}</a>
                                    </Link>
                                </Typography>
                                {dataConfig
                                    && (
                                        <Button onClick={() => router.push('/registervendor')} variant="contained" className={classes.btnVendor}>
                                            <span className={classes.btnVendorText}>{t('login:registerVendor')}</span>
                                        </Button>
                                    )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className={classes.containRight}>
                <img alt="" src="/assets/img/swift-bg-login.png" className={classes.rightImg} />
            </div>
        </div>
    );
};

export default Login;
