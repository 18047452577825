/* eslint-disable prefer-destructuring */
import { getToken, getStoreConfig } from '@modules/login/services/graphql';
import { expiredToken } from '@config';
import { setLogin } from '@helper_auth';
import { useRouter } from 'next/router';
import Layout from '@layout';
import { regexEmail } from '@helper_regex';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const Core = (props) => {
    const {
        Content, storeLogo, t,
    } = props;
    const router = useRouter();
    const pageConfig = {
        title: t('login:pageTitle'),
        header: false,
        sidebar: false,
    };

    const [getCustomerToken] = getToken();
    const handleSubmit = (variables) => {
        window.backdropLoader(true);
        getCustomerToken({
            variables,
        }).then((res) => {
            const token = res.data.internalGenerateCustomerToken.token;
            if (token) {
                setLogin(1, expiredToken);
                router.push('/');
            }
        }).catch((e) => {
            // console.log(e);
            window.backdropLoader(false);
            window.toastMessage({
                open: true,
                variant: 'error',
                text: e.message.split(':')[0],
            });
        });
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required(t('common:required')).matches(regexEmail, t('common:invalidEmail')),
            password: Yup.string().required(t('common:required')),
        }),
        onSubmit: async (values) => {
            handleSubmit(values);
        },
    });

    const { loading: loadingConfig, data: dataConfig } = getStoreConfig({
        path: 'swiftoms_vendorportal/configuration/enable_vendor_portal',
    });

    const contentProps = {
        formik,
        loadingConfig,
        dataConfig: dataConfig && dataConfig.getStoreConfig && dataConfig.getStoreConfig === '1',
        storeLogo,
        t,
    };

    return (
        <Layout pageConfig={pageConfig}>
            <Content {...contentProps} />
        </Layout>
    );
};

export default Core;
